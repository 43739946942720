<template>
  <div class="disk">
    <div class="side-bar">
      <div class="top">
        <div class="logo-wrap">
          <img src="@/assets/logo_blue.png" class="logo" alt="">
          <div class="intro">
            <h2>飞速侠</h2>
            <p class="tip">专业个人云加速服务</p>
          </div>
        </div>

        <div class="menu-wrap">
          <router-link to="/app/task" class="menu-item speed">
            <p class="text">飞速云端空间</p>
          </router-link>
          <router-link to="/app/downloading" class="menu-item downloading">
            <p class="text">下载中</p>
          </router-link>
          <router-link to="/app/downloaded" class="menu-item downloaded">
            <p class="text">下载完成</p>
          </router-link>
          <router-link to="/app/search" class="menu-item search">
            <p class="text">搜索中心</p>
          </router-link>
          <!-- <div class="qrcode-wrap">
            <img class="qrcode" src="https://res.oodcd.cn/ddx/ddx.jpg" alt="">
            <p>扫码领免费流量</p>
          </div> -->
        </div>
      </div>
      <div class="menu-wrap">
        <div class="qrcode-wrap">
          <img class="qrcode" src="https://res.oodcd.cn/fsx/images/qq_qrcode.jpg" alt="">
        </div>
        <a href="https://shimo.im/docs/PqqtjWDHggRtvyvC/" target="_blank" class="menu-item help">
          <p class="text">帮助中心</p>
        </a>
        <router-link to="/app/pc" class="menu-item pc">
          <p class="text">下载客户端</p>
        </router-link>
      </div>

        
    </div>
    <div class="main">
      <status-bar class="status-bar" />
      <router-view class="main-inner" />
    </div>
  </div>
</template>

<script>
import StatusBar from '@/components/StatusBar.vue'
export default {
  data () {
    return {

    }
  },
  components: {
    StatusBar
  },
  mounted () {
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';
.disk {
  height: 100%;
  position: relative;
  padding-left: 200px;
  .side-bar {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 200px;
    bottom: 0px;
    background-color: #fff;
    border-right: 1px solid #eee;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .logo-wrap {
      height: 74px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      .logo {
        height: 40px;
        width: 40px;
        margin-right: 10px;
      }
      h2 {
        font-size: 20px;
      }
      .intro {
        font-size: 12px;
        zoom: 0.9;
      }
    }
    .menu-wrap {
      padding: 0px 20px;
      .menu-item {
        display: flex;
        color: #74798C;
        font-weight: bold;
        align-items: center;
        text-decoration: none;
        padding: 10px 0 10px 40px;
        transition: all ease 0.2s;
        background-repeat: no-repeat;
        background-size: 22px 22px;
        background-position: 10px 50%;
        &.speed {
          background-image: url('../assets/icons/speed.png');
        }
        &.help {
          background-image: url('../assets/icons/help.png');
        }
        &.downloading {
          background-image: url('../assets/icons/downloading.png');
        }
        &.search {
          background-image: url('../assets/icons/search.png');
        }
        &.downloaded {
          background-image: url('../assets/icons/downloaded.png');
        }
        &.pc {
          background-image: url('../assets/icons/pc.png');
        }
        &.router-link-active, &.router-link-exact-active, &:hover {
          color: $theme-color;
          &.speed {
            background-image: url('../assets/icons/speed_active.png');
          }
          &.help {
            background-image: url('../assets/icons/help_active.png');
          }
          &.downloading {
            background-image: url('../assets/icons/downloading_active.png');
          }
          &.downloaded {
            background-image: url('../assets/icons/downloaded_active.png');
          }
          &.pc {
            background-image: url('../assets/icons/pc_active.png');
          }
          &.search {
            background-image: url('../assets/icons/search_active.png');
          }
        }
        .icon {
          height: 18px;
          margin-right: 6px;
        }
        .text {
          color: inherit;
        }
      }
    }
    .qrcode-wrap {
      text-align: center;
      p {
        font-size: 12px;
        margin-top: 5px;
      }
    }
    .qrcode {
      max-width: 90%;
      border-radius: 2px;
      border: 1px solid #eee;
    }
  }
  .main {
    height: 100%;
    position: relative;
    padding-top: 74px;
    .status-bar {
      top: 0;
      left: 0;
      right: 0;
      height: 74px;
      background-color: #fff;
      position: absolute;
      padding-left: 20px;
      padding-right: 20px;
      -webkit-app-region: drag;
    }
    .main-inner {
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar-track {
        width: 4px;
        border-radius: 4px;
        background-color: #f8f8f8;
      }
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        width: 4px;
        border-radius: 3px;
        background: #999;
      }
    }
  }
  
}
</style>
