<template>
  <div class="status-bar">
    <div class="inner">
      <el-button 
        type="primary" 
        size="mini"
        class="no-drag"
        icon="el-icon-plus"
        @click="showUploadDialog"
      >添加文件
      </el-button>

      <div class="right">
        <search class="search" />
        <div class="quantity-wrap">
          <div class="quantity-item item1"  @click="showQuantityDialog">
            <img src="@/assets/energy.png" class="energy">
            <span class="text">{{allQuantity | bytesToSize}}</span>
          </div>
          <div class="quantity-item item2" @click="showPayDialog">
            <span class="text">充值流量</span>
            <i class="el-icon-plus"></i>
          </div>
          
        </div>
        <userinfo />
      </div>
    </div>
  </div>
</template>

<script>
import Search from '@/components/Search.vue'
import Userinfo from '@/components/Userinfo.vue'
export default {
  data() {
    return {
      timer: null,
    }
  },
  components: {
    Search,
    Userinfo,
  },
  async mounted () {
    // 每个一分钟刷新下流量
    this.timer = setInterval(async () => {
      if (global.token) {
        await this.updateQuantity()
      }
    }, 30 * 1000)
  },
  methods: {
    clearTimer () {
      clearInterval(this.timer)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';

.status-bar {
  border-bottom: 1px solid #eee;
  .inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .right {
      display: flex;
      align-items: center;
    }
    .search {
      width: 180px;
      margin-right: 20px;
    }
    .quantity-wrap {
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
    .quantity-item {
      height: 26px;
      display: flex;
      align-items: center;
      padding: 2px 6px;
      cursor: pointer;
      transition: all ease 0.1s;
      background-color: rgba($theme-color, 0.1);
      border: 1px solid rgba($theme-color, 0.2);
      &:hover {
        background-color: rgba($theme-color, 0.3);
      }
      &.item1 {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }
      &.item2 {
        border-left: none;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }
      .energy{
        height: 13px;
        border-radius: 50%;
        margin-right: 4px;
      }
      .text {
        zoom: 0.9;
        font-size: 12px;
        font-weight: bold;
      }
      i {
        margin-left: 2px;
        color: $theme-color;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
}
  
</style>