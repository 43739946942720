<template>
  <div class="search-wrap">
    <input type="text" v-model="keyword" placeholder="网盘资源搜索..." @keyup.enter="search" class="input">
    <i class="el-icon-search search-button" @click="search"></i>
  </div>
</template>

<script>
export default {
  data () {
    return {
      keyword: '',
      engine: 'https://www.baimapan.com'
    }
  },
  methods: {
    search () {
      const query = {
        keyword: this.keyword,
        t: new Date().getTime(),
      }
      if (this.filter.size !== -1) {
        query.size = this.filter.size
      }
      if (this.filter.time !== -1) {
        query.time = this.filter.time
      }
      if (this.filter.type !== -1) {
        query.type = this.filter.type
      }
      if (this.filter.searchtype !== -1) {
        query.searchtype = this.filter.searchtype
      }

      this.$store.commit('changeFilter', { show: false, keyword: this.keyword })
      this.$router.replace({path: '/app/search', query})

      // window.open(`${this.engine}/search?keyword=${encodeURIComponent(this.keyword)}`)
    }
  }
}

</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';
.search-wrap {
  position: relative;
  display: inline-block;
  width: 100%;
  .input {
    width: 100%;
    font-size: 12px;
    border-radius: 18px;
    height: 32px;
    padding-left: 10px;
    padding-right: 20px;
    outline: none;
    background-color: transparent;
    border: 1px solid #e5e5e5;
    transition: all ease 0.3s;
    &:focus {
      border-color: $theme-color;
    }
  }
  .search-button {
    display: inline-block;
    position: absolute;
    height: 20px;
    width: 20px;
    right: 6px;
    top: 8px;
    z-index: 2;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: color ease 0.1s;
    &:hover {
      color: $theme-color;
    }
  }
}
</style>