<template>
  <div class="userinfo">
    <el-popover
      placement="left"
      width="200"
      v-model="visiable"
    >
      <div class="content">
        <p class="info-line"><label>昵称：</label>
        <span class="uid ellipsis">{{userinfo.nickname}}</span>
        </p>
        <p class="info-line">
          <label>ID：</label> 
          <span class="uid ellipsis">{{userinfo.uid}}</span>
          <span class="copy" @click="copy(userinfo.uid)">复制</span>
        </p>
        <p class="txt-center"><span class="logout" @click="logoutAction">退出登录</span></p>
      </div> 
      <div class="userinfo" slot="reference">
        <el-avatar class="avatar" :size="32" :src="userinfo.avatar"></el-avatar>
        <i class="el-icon-arrow-down"></i>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { copy } from '@/util'
import { logout } from '@/api/user'

export default {
  data () {
    return {
      visiable: false,
    }
  },
  methods: {
    copy (text) {
      copy(text)
      this.$message.success('复制成功')
    },
    async logoutAction () {
      const res = await logout()
      if (res.code == 0) {
        await logout()
        this.$message.success('退出成功')
        await this.updateUserinfo()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';
.userinfo {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  .avatar {
    margin-right: 4px;
    border: 1px solid #f2f2f2;
  }
}

.content {
  font-size: 12px;
  .info-line {
    color: #666;
    display: flex;
    align-items: center;
    margin-top: 5px;
    &:first-child {
      margin-top: 0;
    }
    label {
      color: #333;
      font-weight: bold;
    }
    .uid {
      width: 110px;
      display: inline-block;
    }
    .copy {
      margin-left: 4px;
      cursor: pointer;
      color: $theme-color;
    }
    
  }
  .txt-center {
    margin-top: 5px;
  }
  .logout {
      cursor: pointer;
      color: $theme-color;
    }
}
</style>